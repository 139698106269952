import { container, title } from "@assets/jss/index.js";

import imagesStyle from "@assets/jss/imagesStyles.js";

const profilePageStyle = {
  container,
  profile: {
    textAlign: "center",
    "& img": {
      maxWidth: "200px",
      width: "100%",
      margin: "0 auto",
      transform: "translate3d(0, -50%, 0)",
      '@media (min-width: 1024px)': {
        transform: "translate3d(0, -64%, 0)"
      }
    }
  },
  description: {
    fontSize: "1.2rem",
    margin: "1.071rem auto 0",
    maxWidth: "800px",
    color: "#333",
    textAlign: "left"
  },
  name: {
    marginTop: "-80px"
  },
  ...imagesStyle,
  main: {
    background: "#FFFFFF",
    position: "relative",
    zIndex: "3"
  },
  mainRaised: {
    margin: "-60px auto 0px",
    padding: "10px",
    boxShadow:
      "0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)"
  },
  '@media (min-width: 1024px)': {
    mainRaised: {
      borderRadius: "6px",
      paddingTop: "60px",
      paddingBottom: "60px",
      margin: "-60px 30px 0px"
    }
  },
  title: {
    ...title,
    display: "inline-block",
    position: "relative",
    marginTop: "30px",
    minHeight: "32px",
    textDecoration: "none"
  },
  socials: {
    marginTop: "0",
    width: "100%",
    transform: "none",
    left: "0",
    top: "0",
    height: "100%",
    lineHeight: "41px",
    fontSize: "20px",
    color: "#999"
  },
  navWrapper: {
    margin: "20px auto 50px auto",
    textAlign: "center"
  },
  margin: {
    marginBottom: "30px"
  }
};

export default profilePageStyle;
