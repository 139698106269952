import React from 'react'
import { graphql } from 'gatsby'
// import Link from '@components/Link'
import Button from "@material-ui/core/Button"
import Layout from '@components/layout'
import SEO from '@components/seo'
// import { rhythm } from '@utils/typography'
import GridContainer from '@components/Grid/GridContainer'
import GridItem from '@components/Grid/GridItem'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
// @material-ui/icons
import PermPhoneMsg from '@material-ui/icons/PermPhoneMsg'
import BusinessCenter from '@material-ui/icons/BusinessCenter'
import PinDrop from '@material-ui/icons/PinDrop'
import AccountCircle from '@material-ui/icons/AccountCircle'
import Email from '@material-ui/icons/Email'
import Comment from '@material-ui/icons/Comment'
// import FormControl from '@material-ui/core/FormControl'
// core components
// import Input from '@material-ui/core/Input';
// import InputLabel from '@material-ui/core/InputLabel';
// import InputAdornment from '@material-ui/core/InputAdornment';
// import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import InfoArea from '@components/InfoArea/InfoArea.js'
import Grid from '@material-ui/core/Grid'
import Parallax from '@components/Parallax/Parallax'

import WechatCode from '@components/wechat'

// import Button from "@components/CustomButtons/Button"
// import NavPills from "@components/NavPills/NavPills.js"

import styles from '@assets/jss/views/profilePage.js'
const useStyles = makeStyles(styles)

const Contact = props => {
  const siteTitle = props.data.site.siteMetadata.title
  const classes = useStyles()
  // const { ...rest } = props
  // const imageClasses = clsx(
  //   classes.imgRaised,
  //   classes.imgRoundedCircle,
  //   classes.imgFluid
  // )
  // const navImageClasses = clsx(classes.imgRounded, classes.imgGallery)

  return (
    <Layout location={props.location} title={siteTitle}>
      <SEO title="Contact Us" />
      <Parallax style={{ height: '560px', paddingTop: '200px', backgroundColor: "#212121" }}>
        <iframe
          width="100%"
          height="500"
          id="gmap_canvas"
          title="Google Map"
          src="https://maps.google.com/maps?q=Flower%20Dance%20of%20Canada&t=&z=13&ie=UTF8&iwloc=&output=embed"
          frameborder="0"
          scrolling="no"
          marginheight="0"
          marginwidth="0"
        ></iframe>
      </Parallax>
      <div
        className={clsx(classes.main, classes.mainRaised)}
        style={{ marginTop: '-80px', paddingTop: '80px', paddingBottom: '80px' }}
      >
        <div className={classes.container}>
          <GridContainer justify="center">
            <GridItem xs={12} sm={12} md={4}>
              <GridContainer justify="center">
                <GridItem xs={12}>
                  <InfoArea
                    title="地址/Address"
                    description={<div>
                        103 - 6125 Sussex Ave<br />
                      </div>}
                    icon={PinDrop}
                    iconColor="rose"
                  />
                </GridItem>
                <GridItem xs={12}>
                  <InfoArea
                    title="电话/Phone Us"
                    description={<div>
                      604-916-0838<br />
                      9:00am - 6:00pm</div>}
                    icon={PermPhoneMsg}
                    iconColor="rose"
                  />
                </GridItem>
                <GridItem xs={12}>
                  <InfoArea
                    title="微信/Wechat"
                    description={<WechatCode />}
                    icon={BusinessCenter}
                    iconColor="rose"
                  />
                </GridItem>
              </GridContainer>
            </GridItem>
            <GridItem xs={12} sm={12} md={8}>
            <div className={classes.margin}>
              <Grid container spacing={1} justify="center" alignItems="flex-end">
                <Grid item>
                  <h4 style={{ marginTop: 20 }}>联系我们 / Contact Us</h4>
                </Grid>
              </Grid>
              <form method="post" netlify-honeypot="bot-field" data-netlify="true" name="contact">
              <input type="hidden" name="bot-field" />
              <input type="hidden" name="form-name" value="contact" />
              <Grid container spacing={1} alignItems="flex-end">
                <Grid item>
                  <AccountCircle />
                </Grid>
                <Grid item xs={10}>
                  <TextField 
                    id="name" 
                    type="text"
                    name="name"
                    label="名字/name"
                    color="secondary"
                    fullWidth
                    required
                  />
                </Grid>
              </Grid>
              <Grid container spacing={1} alignItems="flex-end">
                <Grid item>
                  <Email />
                </Grid>
                <Grid item xs={10}>
                  <TextField 
                    id="email" 
                    type="email"
                    name="email"
                    label="电子邮件/email"
                    color="secondary"
                    fullWidth
                    required
                  />
                </Grid>
              </Grid>
              <Grid container spacing={1} alignItems="center">
                <Grid item>
                  <Comment />
                </Grid>
                <Grid item xs={10}>
                  <TextField 
                    id="contents" 
                    name="message"
                    label="留言/message"
                    color="secondary"
                    fullWidth
                    multiline
                    rows="8"
                    required
                  />
                </Grid>
              </Grid>
              <Grid container spacing={1} justify="center" alignItems="flex-end">
                <Grid item>
                  <br />
                  <Button type="submit" variant="contained" color="secondary">
                    发送/Send
                  </Button>
                </Grid>
              </Grid>
              </form>
            </div>
            </GridItem>
          </GridContainer>
        </div>
      </div>
    </Layout>
  )
}

export default Contact

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
